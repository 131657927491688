<template>
  <div>
    <h2>{{ $t('views.locations.users.title') }}</h2>

    <relation-table
      :entityKey="eCurrent.key"
      :entityIdKey="eCurrent.idKey"
      :relationKey="relationKey"

      :tableSettings="table.default"
    >
      <template #title>{{ $t('views.locations.users.relationtable.title.default') }}</template>
      <template #killButton>{{ $t('views.locations.users.relationtable.button.kill') }}</template>

      <template #modalTitle>{{ $t('views.locations.users.relationtable.modal.title') }}</template>
      <template #modalBody="data">
        <i18n :path="`views.locations.users.relationtable.modal.body.${data.type}`" tag="p">
          <template #username><b-badge variant="info">{{ data.item.lastname }} {{ data.item.firstname }}</b-badge></template>
          <template #location><b-badge variant="light">{{ parent.name }}</b-badge></template>
        </i18n>
      </template>
      <template #modalAbortButton>{{ $t('views.locations.users.relationtable.modal.button.abort') }}</template>
      <template #modalConfirmButton="data">{{ $t(`views.locations.users.relationtable.modal.button.confirm.${data.type}`) }}</template>
    </relation-table>

    <relation-table
      :entityKey="eCurrent.key"
      :entityIdKey="eCurrent.idKey"
      :relationKey="relationKey"

      :tableSettings="table.relationrequest"
    >
      <template #title>{{ $t('views.locations.users.relationtable.title.relationrequest') }}</template>
      <template #declineButton>{{ $t('views.locations.users.relationtable.button.decline') }}</template>
      <template #acceptButton>{{ $t('views.locations.users.relationtable.button.accept') }}</template>

      <template #modalTitle>{{ $t('views.locations.users.relationtable.modal.title') }}</template>
      <template #modalBody="data">
        <i18n :path="`views.locations.users.relationtable.modal.body.${data.type}`" tag="p">
          <template #username><b-badge variant="info">{{ data.item.lastname }} {{ data.item.firstname }}</b-badge></template>
        </i18n>
      </template>
      <template #modalAbortButton>{{ $t('views.locations.users.relationtable.modal.button.abort') }}</template>
      <template #modalConfirmButton="data">{{ $t(`views.locations.users.relationtable.modal.button.confirm.${data.type}`) }}</template>
    </relation-table>

    <div class="module">
      <h3 class="module-title">{{ $t('views.locations.users.invites.title') }}</h3>

      <userlocation-invitation-table :entityId="eParent.id"/>
    </div>

    <div class="module">
      <h3 class="module-title">{{ $t('views.locations.users.invite.title') }}</h3>
      <userlocation-invite-form :parentId="eParent.id" :entityKey="entityKey" :relationKey="relationKey"/>
    </div>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import RelationTable from '@/components/entity/RelationTable'
import UserlocationInvitationTable from '@/components/entity/UserlocationInvitationTable'
import UserlocationInviteForm from '@/components/entity/UserlocationInviteForm'

const ENTITY_KEY = 'users'
const RELATION_KEY = 'userlocations'

export default {
  name: 'Location.Users',
  mixins: [MixinEntityBase],
  components: {
    RelationTable,
    UserlocationInvitationTable,
    UserlocationInviteForm
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      table: {
        default: {
          id: 'Default',
          helper: TableHelper,
          filter: e => e.isAccepted,
          mapper: this.tableEntitiesMapper,
          search: {
            on: ['lastname', 'firstname'],
            placeholder: this.$t('views.locations.users.relationtable.search.placeholder')
          },
          options: {
            fields: {
              includes: ['$kill'],
              excludes: [],
              sorting: ['lastname', 'firstname', 'function', 'role', '$kill'],
              sortable: [CLASS_TABLE_FIELD_KEY_ALL],
              label: {
                $kill: ''
              },
              variant: {},
              class: {
                $kill: 'cell-min cell-align-right'
              },
              thClass: {},
              tdClass: {}
            }
          },
          events: {
            rowClicked: this.tableRowClicked
          }
        },
        relationrequest: {
          id: 'Relationrequest',
          helper: TableHelper,
          filter: e => !e.isAccepted,
          mapper: this.tableEntitiesMapper,
          search: {
            on: ['lastname', 'firstname'],
            placeholder: this.$t('views.locations.users.relationtable.search.placeholder')
          },
          options: {
            fields: {
              includes: ['$decline', '$accept'],
              excludes: [],
              sorting: ['lastname', 'firstname', '$decline', '$accept'],
              sortable: [CLASS_TABLE_FIELD_KEY_ALL],
              label: {
                $decline: '',
                $accept: ''
              },
              variant: {},
              class: {
                $decline: 'cell-min cell-align-right',
                $accept: 'cell-min cell-align-right'
              },
              thClass: {},
              tdClass: {}
            }
          },
          events: {
            rowClicked: this.tableRowClicked
          }
        }
      }
    }
  },
  computed: {
    parent () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id)
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: this.eParent })
    },
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign(e, {
          $routerLink: {
            name: 'Users.Locations',
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }, e))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    }
  },
  created () {
    TableHelper.rowClassHook(e => e.$routerLink ? 'has-routerlink' : null)
    this.getEntities()
  }
}
</script>

<style lang="scss"></style>
